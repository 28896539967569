<template>
    <div>
        <usuario-form
            acao="INSERIR"
            @salvar="inserir($event)"
            :cancelar="cancelar"
            :erros="erros"
            :usuario="usuario"
            @pesquisarLogin="pesquisarLogin($event)"
        ></usuario-form>
        <Dialog header="Senha" v-model:visible="displayBasic" :style="{ width: '30vw' }">
            <span>
                A senha é:
                <h4>{{ senhatxt }}</h4>
            </span>
            <template #footer>
                <Button label="Fechar" icon="pi pi-check" @click="closeBasic" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>
import UsuariosServices from './services';
import UsuarioForm from './UsuarioForm';

export default {
    components: {
        UsuarioForm,
    },

    data() {
        return {
            erros: [],
            usuario: null,
            senhatxt: null,
            usuarioid: null,
            displayBasic: false,
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Usuarios Sol' });
        },

        mostrarSenha(senhaTxt) {
            this.$confirm.require({
                message: 'Senha do usuário: ' + senhaTxt,
                header: 'Senha',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'surface-0',
                accept: () => {
                    this.$toast.add({ life: 1 });
                },
            });
        },
        inserir(usuarioInserido) {
            this.$store.dispatch('addRequest');
            let usuarioInseridoDto = {
                nome: usuarioInserido.nome,
                login: usuarioInserido.login,
                email: usuarioInserido.email,
                tipo: usuarioInserido.tipo,
                // telefone: usuarioInserido.telefone,
            };
            UsuariosServices.inserir(usuarioInseridoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Usuário inserido com sucesso',
                        life: 3000,
                    });

                    if (response.data.senhaTxt != null && response.data.senhaTxt != '') {
                        this.senhatxt = response.data.senhaTxt;
                        this.usuarioId = response.data.usuarioId;
                        this.openBasic();
                    }
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        openBasic() {
            this.displayBasic = true;
        },
        closeBasic() {
            this.displayBasic = false;
            this.toDetalhar(this.usuarioId);
        },

        pesquisarLogin(login) {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorLogin(login).then((response) => {
                if (response?.success && response.data != '') {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(usuarioId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Usuarios_Sol_Detalhar',
                params: {
                    id: usuarioId,
                },
            });
        },
    },
};
</script>
